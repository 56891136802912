import { map } from "lodash";
// import axios from 'src/utils/axios';
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  eventList: [],
  dailyReport: [],
  passengerBookingReport: [],
  passengerOpenApp: [],
  passengerAllBookings: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // Total no of customers app install and uninstalls list :
    getEventListSuccess(state, action) {
      state.isLoading = false;
      state.eventList = action.payload;
    },

    // Get Daily Reports :
    getDailyReportSuccess(state, action) {
      state.isLoading = false;
      state.dailyReport = action.payload;
    },

    // Get Passenger All Bookings :
    getPassengerAllBookingsSuccess(state, action) {
      state.isLoading = false;
      state.passengerAllBookings = action.payload;
    },

    // Get Daily Reports :
    getPassengerBookingReportSuccess(state, action) {
      state.isLoading = false;
      state.passengerBookingReport = action.payload;
    },

    // Get Passenger Opens App Records:
    getpassengerOpenAppSuccess(state, action) {
      state.isLoading = false;
      state.passengerOpenApp = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed,
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getEventList(values) {
  console.log("paramaaa ==", values);
  const postdata = {
    // fromDate: values.from,
    // toDate: values.to,
    fromDate: "2022-07-01",
    toDate: "2022-07-31",
    deviceType: values.deviceType,
    deviceVersion: values.appVersion,
    generateType: "D",
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    
    try {
      const response = await axios.post(
        "https://rslapp.ae:4040/api/totalEventListMonth",
        postdata
      );
      console.log("repooo  = ", response.data);
      // dispatch(slice.actions.getEventListSuccess(null));
      if (
        response.data.responseData !== undefined &&
        response.data.status === 1
      ) {
        dispatch(slice.actions.getEventListSuccess(response.data.responseData));
      }
      if (response.data.status === 2) {
        dispatch(slice.actions.getEventListSuccess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getDailyReport(values) {
  console.log("paramaaa ==", values);
  const postdata = {
    // fromDate: values.from,
    // toDate: values.to,
    fromDate: "2022-07-01",
    toDate: "2022-07-31",
    deviceType: values.deviceType,
    deviceVersion: values.appVersion,
    generateType: "D",
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "https://rslapp.ae:4040/api/getDailyReports",
        postdata
      );
      console.log("repooo  = ", response.data);
      if (
        response.data.responseData !== undefined &&
        response.data.status === 1
      ) {
        dispatch(
          slice.actions.getDailyReportSuccess(response.data.responseData)
        );
      }
      if (response.data.status === 2) {
        dispatch(
          slice.actions.getDailyReportSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPassengerAllBookings(values) {
  // console.log("paramaaa ==", values);
  const postdata = {
    // fromDate: values.from,
    // toDate: values.to,
    fromDate: "2022-07-01",
    toDate: "2022-07-31",
    deviceType: values.deviceType,
    deviceVersion: values.appVersion,
    generateType: "D",
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "https://rslapp.ae:4040/api/passengerBookingList",
        postdata
      );
      console.log("podaa === " , response.data);
      if (
        response.data.responseData !== undefined &&
        response.data.status === 1
      ) {
        dispatch(
          slice.actions.getPassengerAllBookingsSuccess(response.data.responseData)
        );
      }
      if (response.data.status === 2) {
        dispatch(
          slice.actions.getPassengerAllBookingsSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPassengerBookingReport(values) {
   console.log("passenger paramaaa ==", values);
  const postdata = {
    // fromDate: values.from,
    // toDate: values.to,
    fromDate: "2022-07-01",
    toDate: "2022-07-31",
    deviceType: values.deviceType,
    deviceVersion: values.appVersion,
    generateType: "D",
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "https://rslapp.ae:4040/api/getBookingReport",
        postdata
      );
      console.log("BookingReport  = ", response.data);
      if (
        response.data.responseData !== undefined &&
        response.data.status === 1
      ) {
        dispatch(
          slice.actions.getPassengerBookingReportSuccess(response.data)
        );
      }
      if (response.data.status === 2) {
        dispatch(
          slice.actions.getPassengerBookingReportSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getpassengerOpenApp(values) {
  console.log("paramaaa ==", values);
  const postdata = {
    // fromDate: values.from,
    // toDate: values.to,
    fromDate: "2022-07-01",
    toDate: "2022-07-31",
    deviceType: values.deviceType,
    deviceVersion: values.appVersion,
    generateType: "D",
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "https://rslapp.ae:4040/api/passengerOpenApp",
        postdata
      );
      console.log("passengerOpenApp  = ", response.data);
      if (
        response.data.responseData !== undefined &&
        response.data.status === 1
      ) {
        dispatch(
          slice.actions.getpassengerOpenAppSuccess(response.data.responseData)
        );
      }
      if (response.data.status === 2) {
        dispatch(
          slice.actions.getpassengerOpenAppSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/profile");
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/posts");
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/social/followers");
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/social/friends");
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/social/gallery");
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/manage-users");
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/account/cards");
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/account/address-book");
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/account/invoices");
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        "/api/user/account/notifications-settings"
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/all");
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
